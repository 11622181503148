import via from "./via.jpg"
function App() {
  return (
    <div className="h-screen flex flex-col">
      <div className="m-auto text-center space-y-4">
        <img className="lg:h-3/4" src={via} alt="" />
          <h1 className="text-2xl">Sitemiz Yapım Aşamasındadır</h1>
          <p>
            <a className="underline" href="https://viamedya.net" target="_blank" rel="noreferrer">ViaMedya.net</a>
            <span> tarafından geliştirilmektedir.</span>
          </p>
      </div>
    </div>
  );
}

export default App;
